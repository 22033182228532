import React, { useCallback, useEffect, useState } from "react";
import RightArrIcon from "../../components/Icons/RightArrIcon";
import Fund from "../../components/Dialogs/Fund";
import RequestFund from "../../components/Dialogs/RequestFund";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BURL, formatNum } from "../../services/base";
import { setCashBal, setTransactions } from "../../slices/userSlice";
import { Link } from "react-router-dom";
import TrxTable from "../../components/TrxTable";
import WalletBgPattern2 from "../../components/Patterns/WalletBgPattern2";
import SearchIcon from "../../components/Icons/SearchIcon";
import Greeting from "../../components/Greeting";
import CashIcon from "../../components/Icons/CashIcon";

const CashBalance = () => {
    let disable_virtual_deposit = "";
    const [disableVirtualDeposit, setDisableVirtualDeposit] = useState("Y");
    const [disableVirtualWithdrawal, setDisableVirtualWithdrawal] =
        useState("Y");

    const [fundModalIsOpened, setFundModalIsOpened] = React.useState(false);
    const [requestModalIsOpened, setRequestModalIsOpened] =
        React.useState(false);
    const [wallet, setWallet] = React.useState("false");
    const {
        cash_balance,
        fetchingBalance,
        transactions,
        fetchingTransactions,
    } = useSelector((state) => state.profile);

    const [filteredTransactions, setfilteredTransactions] = useState(
        transactions ?? []
    );
    const [filter, setFilter] = useState({ start_date: "", end_date: "" });

    const filterTrx = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFilter((current) => ({ ...current, [name]: value }));
    };

    const search = () => {
        // use slice to slice away time
        const filtered = transactions
            ? transactions.filter(
                  (each, i) =>
                      (new Date(each.created_at.slice(0, 10)).getTime() >=
                          new Date(filter.start_date).getTime() &&
                          new Date(each.created_at.slice(0, 10)).getTime() <=
                              new Date(filter.end_date).getTime()) ||
                      (new Date(each.created_at.slice(0, 10)).getTime() <=
                          new Date(filter.start_date).getTime() &&
                          new Date(each.created_at.slice(0, 10)).getTime() >=
                              new Date(filter.end_date).getTime())
              )
            : [];
        setfilteredTransactions(filtered);
    };

    useEffect(() => {
        setfilteredTransactions(transactions);
    }, [transactions]);

    const dispatch = useDispatch();
    const getBal = useCallback(() => {
        axios
            .get(`${BURL}get-cash-balance`)
            .then((res) => {
                if (!res?.data?.success) throw new Error(res);
                dispatch(setCashBal(res.data.message));
                setDisableVirtualDeposit(
                    res.data.message.disable_virtual_deposit
                );
                setDisableVirtualWithdrawal(
                    res.data.message.disable_virtual_withdrawal
                );
            })
            .catch((err) => {
                // toast.error("Can't update cash balance")
            });

        if (!fundModalIsOpened) {
            axios.get(`${BURL}customer-transactions-table`).then((res) => {
                if (!res?.data?.success) throw new Error(res);
                dispatch(setTransactions(res.data.message));
            });
        }
    }, [dispatch, fundModalIsOpened]);

    useEffect(() => {
        getBal();
    }, [getBal]);

    return (
        <div>
            <Greeting sub="Your funds are safe and this is how you look today" />

            <div className="flex flex-wrap">
                <div className="bg-primary py-5 relative px-6 md:mr-5 rounded-2xl w-full mb-5 max-w-sm">
                    <WalletBgPattern2 />
                    <span className="float-right text-black">
                        <CashIcon />
                    </span>
                    <div className=" text-white">
                        <p className="text-sm">Naira Wallet Balance</p>
                        <h3 className="font-bold text-2xl font-chivo">
                            ₦{" "}
                            {fetchingBalance
                                ? "..."
                                : formatNum(cash_balance?.customerNairaBalance)}
                        </h3>
                    </div>
                    <div className="flex mt-4 space-x-4 relative">
                        {disableVirtualDeposit == "N" && (
                            <button
                                onClick={() => {
                                    setWallet("naira");
                                    setFundModalIsOpened(true);
                                }}
                                className="flex space-x-2 justify-center items-center bg-white text-dark rounded-lg text-xs font-bold w-1/2 py-3 px-2"
                            >
                                <span>Deposit Funds</span>
                                <RightArrIcon className="fill-dark" />
                            </button>
                        )}
                        {disableVirtualWithdrawal == "N" && (
                            <button
                                onClick={() => {
                                    setWallet("naira");
                                    setRequestModalIsOpened(true);
                                }}
                                className="flex space-x-2 justify-center items-center bg-trov-dark text-white rounded-lg text-xs font-bold w-1/2 py-3 px-2"
                            >
                                <span>Withdraw Funds</span>
                                <RightArrIcon className="fill-white" />
                            </button>
                        )}
                    </div>
                </div>
                {/* <div className='bg-trov-blue py-5 px-6 relative rounded-2xl w-full mb-5 max-w-sm'>
					<WalletBgPattern1 />
					<span className='float-right text-white'><CashIcon /></span>
					<div className='text-white'>
						<p className='text-sm'>USD Wallet Balance</p>
						<h3 className='font-bold text-2xl font-chivo'>
							$ {fetchingBalance ? '...' : formatNum(cash_balance?.customerDollarBalance)}
						</h3>
					</div>
					<div className='relative flex mt-4 space-x-4'>
						<button onClick={() => {setWallet("dollar"); setFundModalIsOpened(true)}} className='flex space-x-2 justify-center items-center bg-white text-dark rounded-lg text-xs font-bold w-1/2 py-3 px-2'>
							<span>Deposit Funds</span>
							<RightArrIcon />
						</button>
						<button onClick={() => {setWallet("dollar"); setRequestModalIsOpened(true)}} className='flex space-x-2 justify-center items-center bg-trov-dark text-white rounded-lg text-xs font-bold w-1/2 py-3 px-2'>
							<span>Withdraw Funds</span>
							<RightArrIcon />
						</button>
					</div>
				</div> */}
            </div>
            <div className="pt-8">
                <div className="flex flex-wrap items-center mb-8 w-fit">
                    <input
                        onChange={filterTrx}
                        value={filter.start_date}
                        name="start_date"
                        className="mb-2 rounded-full md:mr-5 p-3 focus:border outline-none transition-all duration-500 dark:bg-transparent dark:border dark:border-[#E8E8E84D] text-sm w-full md:w-60 bg-light"
                        placeholder="Start Date"
                        type="date"
                    />
                    <input
                        onChange={filterTrx}
                        value={filter.end_date}
                        name="end_date"
                        className="mb-2 rounded-full md:mr-5 p-3 focus:border outline-none transition-all duration-500 dark:bg-transparent dark:border dark:border-[#E8E8E84D] text-sm w-full md:w-60 bg-light"
                        placeholder="Stop Date"
                        type="date"
                    />
                    <button
                        onClick={search}
                        className="font-medium px-7 text-sm py-3 bg-trov-dark dark:bg-white dark:text-trov-dark mb-2 rounded-lg text-white flex justify-center items-center space-x-2"
                    >
                        <span>Search</span>
                        <SearchIcon />
                    </button>
                </div>
            </div>
            <div className="flex justify-between rounded-t-3xl items-center border dark:border-[#E8E8E84D] py-3 px-5">
                <h3 className="text-lg font-bold">Recent Transactions</h3>
                <Link
                    to="/transactions"
                    className="text-xs font-bold text-primary"
                >
                    VIEW ALL
                </Link>
            </div>
            {transactions && transactions[0] && !fetchingTransactions ? (
                <TrxTable
                    transactions={filteredTransactions.filter(
                        (each, i) => i <= 4
                    )}
                />
            ) : (
                ""
            )}
            <Fund
                open={fundModalIsOpened}
                setOpen={setFundModalIsOpened}
                wallet={wallet}
            />
            <RequestFund
                open={requestModalIsOpened}
                setOpen={setRequestModalIsOpened}
                wallet={wallet}
            />
        </div>
    );
};

export default CashBalance;
